<loader [hide]="isDataAvailable"></loader>
<div *ngIf="isDataAvailable">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h3 class="header">Bestätigung</h3>
        </div>
    </div>
    <div *ngIf="messages">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div [ngClass]="{ 'alert-success': !isError, 'alert-danger': isError }" class="alert">
                    <ul class="messages">
                        <li *ngFor="let message of messages">{{ message }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p>Bitte schliessen Sie den Vorgang zum Zurücksetzen Ihres Passwortes ab, indem Sie den Button "Zurücksetzen" drücken</p>
            <button class="btn btn-primary save" (click)="reset()">Zurücksetzen</button>
        </div>
    </div>
</div>
