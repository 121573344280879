<loader [hide]="isDataAvailable"></loader>
<div *ngIf="isDataAvailable">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="header">
                <h3>Passwort zurücksetzen</h3>
            </div>
        </div>
    </div>

    <div *ngIf="messages">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div [ngClass]="{ 'alert-success': !isError, 'alert-danger': isError }" class="alert">
                    <ul class="messages">
                        <li *ngFor="let message of messages">{{ message }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
                <label for="emailAddress">E-Mail-Adresse:</label>
                <input type="text" class="form-control" id="emailAddress" name="emailAddress" [(ngModel)]="resetPasswordModel.emailAddress" />
            </div>
            <div class="form-group">
                <label for="firstName">Vorname:</label>
                <input type="text" class="form-control" id="firstName" name="firstName" [(ngModel)]="resetPasswordModel.firstName" />
            </div>
            <div class="form-group">
                <label for="lastName">Nachname:</label>
                <input type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="resetPasswordModel.lastName" />
            </div>
            <div class="form-group">
                <label for="captcha-image">Sicherheitseingabe:</label>
                <div>
                    <img id="captcha-image" [src]="'data:image/jpg;base64,'+ captchaData" />
                </div>
                <input type="text" class="form-control" id="captchaText" name="captchaText" [(ngModel)]="resetPasswordModel.captchaText" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 button-area">
            <button class="btn btn-primary cancel" (click)="cancel()">Zurück</button>
            <button class="btn btn-primary save" (click)="save()">Passwort zurücksetzen</button>
        </div>
    </div>
</div>

