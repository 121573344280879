<loader [hide]="isDataAvailable"></loader>
<div *ngIf="isDataAvailable">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="header">
                <h3>Passwort ändern</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <p>Ändern Sie auf dieser Seite Ihr Passwort.</p>
        </div>
    </div>
    <div *ngIf="messages">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div [ngClass]="{ 'alert-success': !isError, 'alert-danger': isError }" class="alert">
                    <ul class="messages">
                        <li *ngFor="let message of messages">{{ message }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-6 col-sm-push-6">
            <div class="alert alert-info password-info">
                <ul>
                    <li>Mindestlänge 12 Zeichen</li>
                    <li>Mindestens ein Großbuchstabe</li>
                    <li>Mindestens ein Kleinbuchstabe</li>
                    <li>Mindestens eine Zahl</li>
                </ul>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-sm-pull-6">
            <div class="form-group">
                <label for="currentPassword">Aktuelles Password:</label>
                <input type="password" class="form-control" id="currentPassword" name="currentPassword" [(ngModel)]="changePasswordModel.currentPassword" />
            </div>
            <div class="form-group">
                <label for="newPassword">Neues Passwort:</label>
                <input type="password" class="form-control" id="newPassword" name="newPassword" [(ngModel)]="changePasswordModel.newPassword" />
            </div>
            <div class="form-group">
                <label for="newPasswordRepeat">Passwortwiederholung:</label>
                <input type="password" class="form-control" id="newPasswordRepeat" name="newPasswordRepeat" [(ngModel)]="changePasswordModel.newPasswordRepeat" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 button-area">
            <button class="btn btn-primary cancel" (click)="cancel()">Zurück</button>
            <button class="btn btn-primary save" (click)="save()">Speichern</button>
        </div>
    </div>
</div>
