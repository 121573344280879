import { Injectable } from "@angular/core";
import { AppSettings } from "./app.settings";

@Injectable()
export class ErrorHandler {

    constructor() {

    }
    do(error: any): void {
        if (AppSettings.isDebugging) {
            console.log(error);
        } else {
            console.log("ausloggen");
        }
    }
}

