export enum ResultCode {
    Ok = 0,
    NotAuthorized = 1,
    DbUpdateFailed = 2,
    DbInsertFailed = 3,
    ValidationError = 4,
    GraphApiError = 5,
    GraphApiNewPasswordNotAccepted = 6,
    Failed = 7,
    EntityAllreadyExists = 8,
    Other = 999
}
