import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ResultCode } from "../../enums/resultcode.enum";
import { ErrorHandler } from "../../common/errorHandler";

@Component({
    selector: 'confirm-reset-password',
    templateUrl: './confirm-reset-password.component.html',
    providers: [ErrorHandler, UserService]
})
export class ConfirmResetPasswordComponent implements OnInit {
    private token: string;
    isDataAvailable = true;
    isError = false;
    messages: Array<string>;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private errorHandler: ErrorHandler) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params['token']) {
                this.token = params['token'];
            }
        });
    }

    reset(): void {
        this.isDataAvailable = false;
        this.userService.resetPassword(this.token)
            .then(result => {
                this.isDataAvailable = true;
                if (result.code === ResultCode.Ok) {
                    this.isError = false;
                    this.messages = ['Bitte prüfen Sie Ihr E-Mail-Postfach.'];
                    setTimeout(() => {
                        this.router.navigate(['home']);
                    }, 5000);

                } else {
                    this.isError = true;
                    this.messages = result.messages;
                }
            })
            .catch(error => {
                this.errorHandler.do(error);
            });
    }
}
